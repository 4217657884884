<template>

  <div class="vertical-center" autocomplete="on" v-cloak>
    <transition appear name="fade">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <form class="form-centered" @submit.prevent="signIn">

              <h3 class="mb-3">PLEASE SIGN IN</h3>
              <!-- Company Logo
              <img src="/images/hydrometrix-logo-250x114.png" alt="PORTAIR" class="img-responsive">-->
              <small :class="messageClass" v-if="message">{{ message.value }}</small>
              <small class="text-success mb-3" v-if="formIsPopulated">Please sign in to access your account</small>

              <!-- Email Field -->
              <div class="input-group-prepend">
                <label for="email_address" class="sr-only">Enter your email address</label>
                <span class="input-group-text" id="addon-email">
                  <font-awesome-icon :icon="['far', 'at']" />
                </span>
                <input type="email" id="email_address" class="form-control" placeholder="Enter your email address" aria-describedby="addon-email" autofocus :disabled="formIsProcessing" v-model="form.email_address" autocomplete="username">
              </div>

              <!-- Password Field -->
              <div class="input-group-prepend">
                <label for="password" class="sr-only">Enter your password</label>
                <span class="input-group-text" id="addon-password">
                  <font-awesome-icon :icon="['far', 'key']" />
                </span>
                <input type="password" id="password" class="form-control" placeholder="Enter your password" aria-describedby="addon-password" :disabled="formIsProcessing" v-model="form.password" autocomplete="current-password">
              </div>

              <!-- Submit Button -->
              <button type="submit" id="signIn" class="btn btn-lg btn-primary btn-block" :disabled="!this.formIsPopulated || this.formIsProcessing">SIGN IN <font-awesome-icon class="ml-1" :icon="['far', 'sign-in-alt']" fixed-width /></button>

              <!-- Reset link -->
              <small style="margin-top: 15px"><a href="/request-password-reset" title="Click to reset your user account password">Forgot your password?</a></small>

            </form>
          </div> <!-- /col-12 -->
        </div> <!-- /row -->
      </div> <!-- /container -->
    </transition>
  </div> <!-- /vertical-center -->

</template>

<script>
  const validate = require('validate.js')
  import Notification from '../mixins/Notification.vue'
  export default {
    name: 'signin',
    mixins: [ Notification ],
    data () {
      return {
        formIsProcessing: false,
        error: false,
        form: {
          email_address: '',
          password: ''
        },
        message: {
          value: 'Enter your credentials to continue',
          isNegative: true
        }
      }
    },
    computed: {
      formIsPopulated() {
        let form = this.form
        return !this.error && form.email_address.length > 0 && form.password.length > 0 && !validate({from: form.email_address}, { from: { email: true }})
      },
      messageClass() {
        return this.message.isNegative ? 'text-danger mb-3' : 'text-success'
      }
    },
    watch: {
      'form.email_address': function (val) {
        if (val.length === 0) {
          this.message.value = 'Email address must not be empty'
          this.message.isNegative = true
        } else if (validate({from: val}, { from: { email: true }})) {
          this.message.value = 'Must be a valid email address'
          this.message.isNegative = true
        } else if (!validate({from: val}, { from: { email: true }}) &&  this.form.password.length === 0) {
          this.message.value = 'Please enter your password to continue'
          this.message.isNegative = true
        } else {
          this.error = false
          this.message.value = null
          this.message.isNegative = false
        }
      },
      'form.password': function (val) {
        if (val.length === 0) {
          this.message.value = 'Password must not be empty'
          this.message.isNegative = true
        } else {
          this.error = false
          this.message.value = null
          this.message.isNegative = false
        }
      }
    },
    methods: {
      signIn() {
        // If the user presses enter on the form inputs it fires the signIn event, make sure the form is populated first
        if (!this.formIsPopulated) return

        this.formIsProcessing = true
        this.error = false
        // Submit a PUT request
        io.socket.put('/signin', this.form, (response, jwres) => {
          // Handle errors
          if (jwres.error) {
            // Display the error message
            this.message.value = jwres.body.replace(/[^a-zA-Z ]/g, '')
            this.message.isNegative = true
            // Enable the form inputs
            this.formIsProcessing = false
            // Disable "Please enter your password to continue"
            this.error = true
            // TODO: This should work but having to reable the input as it's currently disabled. Issue for vue in github?
            document.getElementById('signIn').removeAttribute('disabled')
            // Return control to the user
            return
          }
          // Notify the user
          this.notify({ text: 'Signing in to your acccount...' })
          // Prevent the user from using back with .replace
          setTimeout(() => { window.location.replace('/') }, 1000)
        })
      }
    },
  }
</script>

<style lang="scss">
  @import '../styles/bootstrap-override';
  @import "~noty/src/noty.scss";
  @import "~noty/src/themes/bootstrap-v4.scss";
  @import '../styles/opensans';
  @import '../styles/vue';

  // vertical-center a div
  html {
    height: 100%;
  }

  body {
    font-family: 'open_sansregular';
    height: 100%;
  }

  em {
    font-family: 'open_sansitalic';
  }

  strong {
    font-family: 'open_sansbold';

    em {
      font-family: 'open_sanssemibold_italic';
    }
  }

  .vertical-center {
    font: 0/0 a; // remove the gap between inline(-block) elements
    height: 100%;
    margin-bottom: 0; // Remove the default bottom margin of .jumbotron
    text-align: center;  // align the inline(-block) elements horizontally
    width: 100%;

    > .container {
      display: inline-block;
      font: 16px/1 "open_sansregular"; // <-- reset the font property
      max-width: 100%;
      vertical-align: middle; // vertical alignment of the inline element
    }
  }

  .vertical-center::before {    // create a full-height inline block pseudo=element
    content: ' ';
    display: inline-block;
    height: 100%;
    vertical-align: middle;  // vertical alignment of the inline element
  }

  @media (max-width: 768px) {
    .vertical-center::before {
      // height: auto;
      display: none;
    }
  }


  // the sign in form
  .form-centered {
    margin: 0 auto;
    max-width: 330px;
    padding: 15px;

    img {
      margin: 0 auto;
      margin-bottom: 15px;
    }

    small {
      display: block;
    }

    .input-group-prepend {
      margin-bottom: 20px;

      label {
        font-weight: normal;
        margin-bottom: 15px;
      }

      .input-group-text {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      .form-control {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }

    .form-control {
      box-sizing: border-box;
      font-size: 16px;
      height: auto;
      padding: 10px;
      position: relative;
    }

    .form-control:focus {
      z-index: 2;
    }
  }

</style>