//  ┌─┐┌─┐┬┬  ┌─┐  ┬┌─┐
//  └─┐├─┤││  └─┐  ││ │
//  └─┘┴ ┴┴┴─┘└─┘  ┴└─┘

// Require the sockets.js file if you want to be able to use the socket client to
// do things like `io.socket.get()` inside of this script.
const io = require('../dependencies/sockets.js')

// To make the socket client available globally, uncomment the next line:
window.io = io;

// When the server is shut down send the user to the homepage
io.socket.on('disconnect', reason => {
  setTimeout(() => {
    //toastr.warning('Server is going down for maintenance!')
    alert('Server is going down for maintenance!')
    setTimeout(() => { window.location.replace('/') }, 15000)
  }, 60000)
})

//  ┌─┐┌┬┐┬ ┬┬  ┌─┐┌─┐
//  └─┐ │ └┬┘│  ├┤ └─┐
//  └─┘ ┴  ┴ ┴─┘└─┘└─┘

// Require the styles for this page.  This will cause the `signin.bundle.css` file
// to be generated.  Note that you can require multiple .less or .css files here, and
// they will _all_ be bundled together into `signin.bundle.css` (the name is based
// on the name of this JavaScript file).
require('../styles/pages/signin.scss')

//  ┌┐ ┌─┐┌─┐┌┬┐┌─┐┌┬┐┬─┐┌─┐┌─┐
//  ├┴┐│ ││ │ │ └─┐ │ ├┬┘├─┤├─┘
//  └─┘└─┘└─┘ ┴ └─┘ ┴ ┴└─┴ ┴┴

// styles loaded via sass
import bootstrap from 'bootstrap'

//  ┌─┐┌─┐┌┐┌┌┬┐  ┌─┐┬ ┬┌─┐┌─┐┌─┐┌┬┐┌─┐
//  ├┤ │ ││││ │   ├─┤│││├┤ └─┐│ ││││├┤
//  └  └─┘┘└┘ ┴   ┴ ┴└┴┘└─┘└─┘└─┘┴ ┴└─┘

// https://fontawesome.com/how-to-use/with-the-api/setup/importing-icons
// https://fontawesome.com/how-to-use/on-the-web/using-with/vuejs
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAt } from '@fortawesome/pro-regular-svg-icons/faAt'
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey'
import { faSignInAlt } from '@fortawesome/pro-regular-svg-icons/faSignInAlt'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faAt, faKey, faSignInAlt)
Vue.component('font-awesome-icon', FontAwesomeIcon)

//  ┬  ┬┬ ┬┌─┐
//  └┐┌┘│ │├┤
//   └┘ └─┘└─┘

// import Vue from 'vue'
// import store from '../store'
// import Signin from '../components/Signin'

// new Vue({
//   //el: '#app',
//   store: store,
//   components: { Signin },
//   render: h => h(Signin)
// }).$mount('#app')


// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
//import store from '../store'
//import store from '../store/signin.js'
import Signin from '../page/Signin.vue'

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  //store,
  render: h => h(Signin)
});