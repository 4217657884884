<script>
  import Noty from 'noty'
  export default {
    methods: {
      notify(givenOptions) {
        const defaultOptions = {
          theme: 'bootstrap-v4',
          type: 'success',
          layout: 'topCenter',
          timeout: 5000,
        }
        const newOptions = { ...defaultOptions, ...givenOptions }
        new Noty(newOptions).show()
      }
    },
  }
</script>


